var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "border": "1px solid #F2F2F2",
      "box-shadow": "0px 5px 30px rgba(0, 0, 0, 0.05)",
      "border-radius": "md",
      "mt": "10px",
      "p": ['20px', '28px'],
      "w": ['100%', '739px']
    }
  }, [_c('c-image', {
    attrs: {
      "w": "140px",
      "h": "140px",
      "border-radius": "md",
      "src": _vm.getPhotoProgram(),
      "alt": _vm.title,
      "display": ['none', 'block']
    }
  }), _c('c-box', {
    attrs: {
      "pl": ['0', '24px'],
      "w": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "border-bottom": "1px solid #f4f4f4",
      "align-items": "center",
      "pb": "20px"
    }
  }, [_c('c-flex', [_c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "align-items": "flex-start"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "48px",
      "h": "48px",
      "border-radius": "6px",
      "over-flow": "hidden",
      "margin-right": "20px",
      "display": ['block', 'none']
    }
  }, [_c('c-image', {
    attrs: {
      "w": "48px",
      "h": "48px",
      "border-radius": "6",
      "src": _vm.getPhotoProgram(),
      "alt": _vm.title
    }
  })], 1), _c('c-box', {
    attrs: {
      "display": ['block', 'flex']
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "bold",
      "font-size": "14px",
      "line-height": "150%",
      "color": "#111111",
      "mr": "5px",
      "pt": "0",
      "display": ['block', 'inline-block']
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-text', {
    attrs: {
      "font-size": "14px",
      "line-height": "150%",
      "color": "#111111"
    }
  }, [_vm._v(" (" + _vm._s(_vm.level) + ") ")])], 1)], 1)], 1)], 1), _c('table', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "my": "20px",
      "w": ['100%', 'inherit']
    }
  }, [_c('tr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "h": "25px"
    }
  }, [_c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-size": "12px",
      "color": "primary.400"
    }
  }, [_vm._v(" Invoice ")]), _c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "max-width": ['inherit', '100px'],
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333",
      "white-space": "nowrap",
      "text-overflow": "ellipsis",
      "overflow": "hidden"
    }
  }, [_vm._v(" " + _vm._s(_vm.invoiceId) + " ")])]), _c('tr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "h": "25px"
    }
  }, [_c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-size": "12px",
      "color": "primary.400"
    }
  }, [_vm._v(" Durasi ")]), _c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(_vm.duration) + " ")])]), _c('tr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "h": "25px"
    }
  }, [_c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-size": "12px",
      "color": "primary.400"
    }
  }, [_vm._v(" Status Pembayaran ")]), _c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(_vm.status) + " ")])]), _c('tr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "h": "25px"
    }
  }, [_c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-size": "12px",
      "color": "primary.400"
    }
  }, [_vm._v(" Harga ")]), _c('td', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333"
    }
  }, [_vm._v(" Rp" + _vm._s(Number(_vm.price).toLocaleString("id")) + " ")])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }