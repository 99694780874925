var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "mx": [null, '20px'],
      "mt": [null, '25px'],
      "mb": ['80px', null]
    }
  }, [_c('h1', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-weight": "bold",
      "font-size": "18px",
      "color": "#111"
    }
  }, [_vm._v(" Riwayat Pembelian ")]), _c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333",
      "mt": "25px"
    }
  }, [_vm._v(" Program yang pernah dibeli ")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "7px"
    }
  }), _vm.isEmpty ? _c('EmptyCard', {
    attrs: {
      "label": "Riwayat Pembelian tidak tersedia"
    }
  }) : _vm._e(), !_vm.isEmpty ? _c('c-box', _vm._l(_vm.pembelian, function (item) {
    return _c('PembelianCard', {
      key: item.id,
      attrs: {
        "title": item.productName,
        "level": item.programService,
        "ahli-gizi": item.ahliGizi,
        "duration": item.duration,
        "status": item.status,
        "invoice-id": item.invoiceNumber,
        "price": item.total
      }
    });
  }), 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }